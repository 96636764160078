.voucher-container {
  max-width: 210mm;
  margin: 20px auto;
  border: 1px solid #000;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.voucher-header {
  text-align: center;
}

.voucher-header h2, .voucher-header p, .voucher-header h3 {
  margin: 5px 0;
}

.voucher-info, .amount-words, .voucher-footer {
  margin: 20px 0;
}

.info-row {
  display: flex;
  justify-content: space-between;
}

.voucher-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.voucher-table th, .voucher-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.amount-words p {
  font-style: italic;
}

.footer-row {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  font-weight: bold;
}

@media print {
  .voucher-container {
    max-width: 190mm; /* Slightly reduce width for PDF compatibility */
    margin: 10px;
  }
 .voucher-actions {
      display: none; /* Hide buttons when printing */
    }
}

