/* Chat.css */
.chat-container {
    display: flex;
    height: 100vh;
}

.chat-container > Sidebar {
    width: 50px;
    background-color: #f4f4f4; /* Optional: Adjust background color */
    display: flex;
    flex-direction: column;
}

.chat-container > Main {
    flex-grow: 1;
    background-color: #fff; /* Optional: Adjust background color */
}
