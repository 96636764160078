/* PurchaseOrder.css */
.po-container {
    max-width: 210mm;
    margin: 20px auto;
    padding: 20px;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    font-size: 12px;
    
  }
  
  .header {
    display: grid;
    grid-template-columns: 50px 1fr 200px;
    gap: 20px;
    margin-bottom: 20px;
    width:'60%';
  }
  
  .company-logo {
    font-size: 48px;
    font-weight: bold;
    border: 1px solid #000;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .company-info h2 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .company-info p {
    margin: 2px 0;
    font-size: 12px;
    text-align: center;
  }
  
  .po-info table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .po-info td {
    padding: 2px;
    border: 1px solid #ddd;
  }
  
  .po-title {
    text-align: center;
    margin: 15px 0;
    padding-top: 12px;
    font-size: 24px;
  
  }
  
  .vendor-details {
    margin: 15px 0;
    border:1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  
  .vendor-row {
    margin: 5px 0;
  }
  
  .main-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
  }
  
  .main-table th,
  .main-table td {
    border: 1px solid #000;
    padding: 5px;
    text-align: left;
    font-size: 11px;
  }
  
  .main-table th {
    background-color: #f8f8f8;
  }
  
  .total-text {
    text-align: right;
    font-weight: bold;
  }
  
  .terms-section {
    margin: 15px 0;
  }
  
  .terms-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
  }
  
  .important-note {
    margin: 15px 0;
  }
  
  .important-note ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .important-note li {
    margin: 5px 0;
    font-size: 11px;
  }
  
  .signature {
    margin-top: 30px;
    text-align: right;
  }
  
  .sign-line {
  
    width: 200px;
    margin-left: auto;
    padding-top: 5px;
    margin-top: 40px;
  }
  
  .sign-line p {
    margin: 0;
    text-align: center;
  }
  
  @media print {
    .po-container {
      box-shadow: none;
      margin: 0;
      padding: 10px;
    }
   .sale-actions {
        display: none; /* Hide buttons when printing */
      }
  }