.container{
    width: 700px;
    height: 1000px;
    margin: 0 auto;
    padding: 20px; /* Optional padding */
    background-color: white; /* Background for clear print layout */
}
.receipt-note {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

header {
    text-align: center;
    /* border-bottom: 2px solid #000; */
    padding-bottom: 10px;
    margin-bottom: 20px;
}

header h1 {
    font-size: 24px;
    margin: 0;
    font-weight: bold;
}

header p {
    margin: 5px 0;
    font-size: 14px;
}

header h2 {
    font-size: 18px;
    margin: 10px 0;
    text-transform: uppercase;
    text-decoration: underline;
}

.receipt-details {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 14px;
}

.left-column, .right-column {
    width: 48%;
}

.receipt-details p {
    margin: 5px 0;
    display: flex;
    gap: 10px;
}

.receipt-details strong {
    min-width: 140px;
}

.material-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
}

.material-table th, 
.material-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}

.material-table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.material-table td {
    vertical-align: top;
}

.totals {
    text-align: right;
    margin: 20px 0;
    border-top: 1px solid #000;
    padding-top: 10px;
}

.totals p {
    margin: 5px 0;
    font-size: 14px;
}

.totals strong {
    display: inline-block;
    min-width: 120px;
    margin-right: 20px;
}

footer {
    margin-top: 50px;
    border-top: 1px solid #000;
    padding-top: 20px;
}

.signatures {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.signatures span {
    position: relative;
    display: inline-block;
    padding-top: 30px;
    min-width: 150px;
}

.signatures span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    border-top: 1px solid #000;
}

@media print {
    .receipt-note {
        border: none;
        box-shadow: none;
        padding: 0;
    }
    .sale-actions{
        display: none
    }
    .signatures {
        flex-wrap: wrap;
        gap: 20px;
    }
    .signatures span {
        width: calc(50% - 10px);
    }
    
    @page {
        margin: 0.5cm;
    }
}

@media (max-width: 768px) {
    .receipt-details {
        flex-direction: column;
    }
    
    .left-column, 
    .right-column {
        width: 100%;
    }
    
    .signatures {
        flex-wrap: wrap;
        gap: 20px;
    }
    
    .signatures span {
        width: calc(50% - 10px);
    }
}