.SiteLayout{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    background-color: transparent;
    width: max-content;
}

.Sider{
    background-color: rgba(255, 255, 255, 0.863);  
    backdrop-filter: blur(20px);
    transition: 0.3s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

.Sider:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.row-end {
    display: flex;
    /* justify-content: flex-end; */
  }
  
  .text {
    color: #1560BD;
    font-size: 10px;
    font-weight: bold;
    margin-top: 5px;
    /* text-align: right; */
  }
  
  .first-item {
      margin-top: 5px; 
    /* padding-top:5px; */
    color: #1560BD;
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 0;
    text-align: right;
    /* padding:5px; */
  }


