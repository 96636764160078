* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.main {
    flex: 1;
    min-height: 100vh;
    padding-bottom: 0vh; 
    background-color: #c6f8f9;
    overflow-x: hidden;
}

.main .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 32px;
    font-weight: 400;
    padding: 15px;
    font-family: 'Times New Roman', Times, serif;
    color: #150bd2;
    position: relative;
}

.genie-photo {
    width: 90px;
    border-radius: 100%;
    position: absolute;
    top: 20px;
    right: 20px;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}

.ctrl-q-text {
    font-size: 10px;
    font-weight: bold; 
    color: #000;
    position: absolute;
    top: 25px;
    left: 50%; 
    transform: translateX(-50%);
    white-space: nowrap;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px; 
    height: 25px; 
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px; 
    width: 21px; 
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(25px); 
}

.toggle-tooltip {
    font-size: 14px;
    color: #000;
    visibility: hidden;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: -50px; 
    left: 50%; 
    transform: translateX(-50%); 
    white-space: nowrap;
}

.toggle-container:hover .toggle-tooltip {
    visibility: visible;
}

.main-container {
    max-width: 900px;
    margin: auto;
    padding: 0 20px;
}

.main .greet {
    margin: 17px 0px;
    font-size: 50px;
    color: #c4c7c5;
    font-weight: 500;
    padding: 15px;
    text-align: center;
}

.main .greet span {
    background: -webkit-linear-gradient(16deg, #4b90ff, #ff5546);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
}

.main .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    padding: 15px;
}

.main .card {
    height: 180px;
    padding: 12px;
    background-color: #f0f4f9;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
}

.main .card:hover {
    background-color: #dfe4ea;
}

.main .card img {
    width: 30px;
    padding: 5px;
    position: absolute;
    background-color: white;
    border-radius: 20px;
    bottom: 10px;
    right: 10px;
}

.main .card p {
    color: #585858;
    font-size: 17px;
}

.main-bottom {
    width: 100%;
    max-width: 900px;
    margin: auto;
    text-align: center;
    padding: 20px;
}

.search-box {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: #f0f4f9;
    padding: 10px 20px;
    border-radius: 50px;
    width: calc(100% - 40px);
    max-width: 900px;
    z-index: 1000;
}

.search-box textarea {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
    resize: vertical;
    overflow-y: auto;
    max-height: 80px;
    min-height: 24px;
}

.search-box img {
    width: 24px;
    cursor: pointer;
}

.main .bottom-info {
    font-size: 12px;
    margin-top: 5px;
    color: #000;
    text-align: center;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 999;
    padding: 0 20px;
}

.result {
    padding: 0px 5%;
    max-height: 70vh;
    overflow-y: auto;
}

.result-title {
    margin: 40px 0px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.result img {
    width: 40px;
    border-radius: 50%;
}

.result-data {
    display: flex;
    align-items: start;
    gap: 20px;
}

.loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loader hr {
    border-radius: 4px;
    border: none;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #9ed7ff, #ffffff, #9ed7ff);
    background-size: 800px 50px;
    height: 20px;
    animation: loader 3s infinite linear;
}

@keyframes loader {
    0% {
        background-position: -800px 0px;
    }
    100% {
        background-position: 800px 0px;
    }
}

.result-data p {
    font-size: 17px;
    font-weight: 300;
    line-height: 1.8;
}

.history {
    margin-top: 20px;
    padding: 10px;
    background-color: #94f1f3;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.history .user-message {
    text-align: right;
    margin: 10px 0;
}

.history .user-message p {
    display: inline-block;
    background-color: #b9bbbd;
    color: #000000;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
}

.history .model-message {
    text-align: left;
    margin: 10px 0;
}

.history .model-message p {
    display: inline-block;
    background-color: #d1ecf1;
    color: #0c5460;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
}

@media (max-width: 600px) {
    .main .greet {
        font-size: 40px;
    }

    .main .cards {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .search-box {
        padding: 5px 10px;
        gap: 10px;
    }

    .search-box textarea {
        font-size: 14px;
    }

    .bottom-info {
        font-size: 10px;
        bottom: 0px;
    }
}