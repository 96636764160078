/* Reduce row height */
.custom-row {
    height: 16px; /* Adjust row height */
    font-size: 14px; /* Improve readability */
    text-align: center; /* Align text in the middle of cells */
    border:"2px solid red"
  }
  /* Center-align numeric data */
  .numeric-cell {
    text-align: right; /* Horizontal alignment */
    vertical-align: middle; /* Vertical alignment */
  }

  .debit-value {
    color: red;
  }
  
  .credit-value {
    color: green;
  }
  
  .closing-balance-positive {
    color: blue;
  }
  
  .closing-balance-negative {
    color: purple;
  }

  .tableStriped tbody tr:nth-of-type(odd) {
    background-color: rgba(25, 118, 210, 0.15); /* Example: semi-transparent blue */
  }
  
  .tableStriped tbody tr:hover {
    background-color: #ffffff; /* White hover background */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    color: #1976d2; /* Primary color (adjust as needed) */
  }
  
  