.TableView{
    width: 100% !important;
    display: block !important;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    background-color: transparent;
}

.Cell{
    font-family: 'Montserrat';
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    padding: 12px !important;
}

.Cell:hover{
    color: #1777C4;
}

.SaveButton{
    text-align: center;
    white-space: nowrap;
    color: #355576;
    width: fit-content;
    padding: 0rem 2rem;
    float: right;
    font-weight : 900;
    font-family: Montserrat;
    font-size: 0.8rem;
    letter-spacing: -0.54px;
    line-height: 2rem;
    border-radius: 40px;
    background-color: inherit;
    border: none;
    outline: none;
    background-color: #79caf3c7;
    margin-bottom: 1rem;
}

.Paper{
    width: 100% !important;
    display: block !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
    /* background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); */
    background-color: transparent;
    font-weight: 900 !important;
    text-transform: capitalize !important;
    /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important; */
}

