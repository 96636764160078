.sidebar {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f0f4f9;
    padding: 15px 10px;
}

.sidebar img {
    width: 18px; 
}

.sidebar .menu {
    display: block;
    margin-left: 8px;
    cursor: pointer;
}

.sidebar .new-chat {
    margin-top: 40px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px; 
    background-color: #e6eaf1;
    border-radius: 50px;
    font-size: 13px;
    color: gray;
    cursor: pointer;
}

.sidebar .recent {
    display: flex;
    flex-direction: column;
    animation: fadeIn 1.5s;
}

.sidebar .recent-title {
    margin-top: 25px; 
    margin-bottom: 15px;
}

.sidebar .recent-entry {
    display: flex;
    align-items: start;
    gap: 10px;
    padding: 8px;
    padding-right: 35px; 
    border-radius: 50px;
    color: #282828;
    cursor: pointer;
}

.sidebar .recent-entry:hover {
    background-color: #e2e6eb;
}

.sidebar .bottom {
    display: flex;
    flex-direction: column;
}

.sidebar .bottom-item {
    padding-right: 8px; 
    cursor: pointer;
}

@media (max-width: 325px) {
    .sidebar {
        display: none;
    }
}

